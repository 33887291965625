<!-- @format -->
<script lang="ts">
  import { onMount } from "svelte";
  import type { StoresKey } from "~/stores";
  import { getStoresContext } from "~/stores";
  import PinkWave from "../common/PinkWave.svelte";
  import OutlineTitle from "../common/OutlineTitle.svelte";
  import HighlightPillButton from "../HighlightPillButton.svelte";
  import BodyContent, { NONE } from "../BodyContent.svelte";
  import Carousel from "./Carousel.svelte";
  import OfferCard from "./OfferCard.svelte";
  import type { ManageAPI } from "~/manage-api/types";

  export let request_store_key: StoresKey;
  export let fragment: any;
  export let prefetched_offers: ManageAPI.Offer[] = [];
  export let show_filters: boolean = false;
  export let offersDisplay: boolean = true;

  let pinkWavePosition: any = '';
  let isVisible = false; // Control visibility to prevent FOUC

  const stores_context = getStoresContext(request_store_key);
  $: offerStreamID = getFragmentConfig("offerStreamID", fragment); // 0: All, 1: Basic, 2: What's new, 3: Services, 4: Responsibility. 
  $: showTitle = getFragmentConfig("showTitle", fragment);
  $: showMoreLink = getFragmentConfig("showMoreLink", fragment);
  $: pinkWavePosition = getFragmentConfig("pinkWavePosition", fragment);
  $: border = getFragmentConfig("border", fragment);
  const { offers, T, reverse_router } = stores_context;
  const { getFragmentConfig } = stores_context.fieldUtils();

  $: t = $T;
  
  const BASIC = 1,
    WHATS_NEW = 2;

  const filterBy = (stream_id: number, include_orphans: boolean) => () => {
    return getOffersByStream(stream_id, include_orphans);
  };

  const filter_btns = [
    {
      id: BASIC,
      label: 'offers-stream-basic',
      filter: filterBy(BASIC, true),
      className: 'offer-lift-switch-button-left'
    },
    {
      id: WHATS_NEW,
      label: 'offers-stream-whats-new',
      filter: filterBy(WHATS_NEW, false),
      className: 'offer-lift-switch-button-right'
    }
  ];

  let filter_active = BASIC;
  let offers_filtered: ManageAPI.Offer[] = prefetched_offers; // Start with prefetched offers

  function toggle(btn: any) {
    filter_active = btn.id;
  }                                                                                                                                                                                                                                                                                                                                                                                                                             

  function getOffersByStream(stream_id: number, include_orphans: boolean) {
    return $offers.filter((offer) => {
      const streams = offer.publicationStreams || [];
      const is_in_stream = streams.some(({ id }) => id == stream_id);
      const no_streams = !streams.length;
      return include_orphans ? is_in_stream || no_streams : is_in_stream;
    });
  }

  $: more_offers_href = $reverse_router && showMoreLink
    ? $reverse_router.findPurposeRoute("offers collection")
    : "";

  let id = 0;
  function focusOffer(event: CustomEvent) {
    id = event.detail;
  }

  // Update filtered offers when filter changes
  $: if (filter_active && $offers && $offers.length > 0) {
    offers_filtered = filter_btns.find((btn) => btn.id == filter_active)?.filter() || [];
  }

  // Make component visible once mounted and data is ready
  onMount(() => {
    // Short delay to ensure styles are applied
    setTimeout(() => {
      isVisible = true;
    }, 100);
  });
</script>

{#if offers_filtered && offers_filtered.length && offersDisplay}
  <div class="mylly-offer-lift-container" class:is-visible={isVisible}>
    <PinkWave will_be={pinkWavePosition}>
      <BodyContent padding={NONE}>
        <div class="offer-lift-wrapper" data-nosnippet>
          <!-- Offer title -->
          {#if showTitle}
            <OutlineTitle>
              <div>{t`offers`}</div>
              <br />
            </OutlineTitle>
          {/if}

          <!-- Filter component -->
          {#if show_filters}
            <div class="offer-lift-switch-button">
              {#each filter_btns as btn}
                <!-- svelte-ignore a11y-click-events-have-key-events -->
                <div
                  class:active={btn.id == filter_active}
                  class={btn.className}
                  on:click={() => toggle(btn)}
                >
                  {t(btn.label)}
                </div>
              {/each}
            </div>
          {/if}

          <!-- Offer carousel -->
          <Carousel slidesCount={offers_filtered?.length} showPagination={false}>
            <svelte:fragment slot="swiper">
              {#each offers_filtered as offer}
                <div class="swiper-slide">
                  <OfferCard
                    {offer}
                    bordered={border}
                    on:focusOffer={focusOffer}
                    {request_store_key}></OfferCard>
                </div>
              {/each}
            </svelte:fragment>
            <svelte:fragment slot="link-button">
              {#if showMoreLink}
                <a href={more_offers_href} class="offer-lift-link-button">
                  {t`offers-show-more`}
                </a>
              {/if}
            </svelte:fragment>
          </Carousel>

          <!-- Show more offer link -->
          {#if showMoreLink && false}
            <HighlightPillButton href={more_offers_href} class="section-button">
              {t`offers-show-more`}
            </HighlightPillButton>
          {/if}
        </div>
      </BodyContent>
    </PinkWave>
  </div>
{/if}

<style lang="scss">
  .mylly-offer-lift-container {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease;
    
    &.is-visible {
      visibility: visible;
      opacity: 1;
    }
  }

  .offer-lift-wrapper {
    width: 100%;
    position: relative;
    :global(.section-button) {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
    }
    
    /* Ensure proper spacing for cards on mobile */
    :global(.swiper-slide) {
      padding: 0 5px;
      
      @media screen and (min-width: 576px) {
        padding: 0;
      }
    }
  }

  .offer-lift-link-button {
    display: inline-block;
    padding: 10px 30px;
    background-color: #fa4616;
    color: white;
    text-decoration: none;
    border-radius: 23px;
    font-weight: 600;
    transition: background-color 0.2s ease;
    margin: 0 auto;
    text-align: center;
    
    &:hover {
      background-color: darken(#fa4616, 10%);
      color: white;
      text-decoration: none;
    }
  }

  .offer-lift-switch-button {
    text-align: center;
    width: 100%;
  }

  .offer-lift-switch-button-left,
  .offer-lift-switch-button-right {
    width: 45%;
    height: 43px;
    cursor: pointer;
    display: inline-block;
    border: 1.5px solid #fa4616;
    position: relative;
    line-height: 40px;
    background: #fff;
    color: #fa4616;
  }

  .offer-lift-switch-button-left.active,
  .offer-lift-switch-button-right.active {
    background: #fa4616;
    color: #fff;
  }

  .offer-lift-switch-button-left {
    border-radius: 23px 0 0 23px;
    right: -2.5px;
  }

  .offer-lift-switch-button-right {
    border-radius: 0 23px 23px 0;
    left: -2.5px;
  }

  @media screen and (min-width: 576px) {
    .offer-lift-switch-button-left,
    .offer-lift-switch-button-right {
      width: 210px;
    }
  }

  .section-button {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 300px;
    height: 45px;
    margin-top: 200px;
    border-radius: 23px;
    text-align: center;
    font-size: 20px;
    line-height: 45px;
    color: #fff;
    background: #fa4616;

    @media screen and (min-width: 576px) {
      margin-top: 270px;
    }
  }
</style> 